import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor(private toastr: ToastrService) {}

  public success(title?: string, msg?: string): void {
    this.toastr.success(msg, title, {
      toastClass: 'success',
    });
  }
  public warning(title?: string, msg?: string): void {
    this.toastr.warning(msg, title, {
      toastClass: 'warning',
    });
  }
  
  public error(title?: string, msg?: string): void {
    this.toastr.error(msg, title, {
      toastClass: 'error',
    });
  }
  public info(title?: string, msg?: string): void {
    this.toastr.info(msg, title, {
      toastClass: 'information',
    });
  }
}
